export const ProductService = {
    getProductsData() {
        return [
            {
                id: 55479,
                name:'Shaik Rizwana Khatun',
                project:'Banking Vertical Pool',
                billable:'12%'
            },
            {
                id: 55648,
                name:'Gayathri K',
                project:'Banking Vertical Pool',
                billable:'12%'
            },
            {
                id: 57562,
                name:'Sabbavarapu Joshini',
                project:'Banking Vertical Pool',
                billable:'10%'
            },
            {
                id: 54345,
                name:'Putla Reshma',
                project:'Banking Vertical Pool',
                billable:'10%'
            },
            {
                id: 63743,
                name:'Boddoju Priyanka',
                project:'Banking Vertical Pool',
                billable:'12%'
            }
              
        ];
    },

    

    getProductsMini() {
        return Promise.resolve(this.getProductsData().slice(0, 5));
    },

    getProductsSmall() {
        return Promise.resolve(this.getProductsData().slice(0, 10));
    },

    getProducts() {
        return Promise.resolve(this.getProductsData());
    },

    getProductsWithOrdersSmall() {
        return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
    },

    getProductsWithOrders() {
        return Promise.resolve(this.getProductsWithOrdersData());
    }
};

