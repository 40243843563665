import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import Product from "../api/ProductTable";
import { useRouter } from "next/router";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Button } from "primereact/button";
import MixpanelRecord from "../MixpanelEvent";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import getCookieValue from "../GetCookie";

export default function TeamBillability() {
  const [products, setProducts] = useState([]);
  const router = useRouter();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [value, setValue] = useState([]);
  const [isFlipped, setIsFlipped] = useState(false);

  const headers = { Authorization: "Bearer " + getCookieValue("token") };
  const handleClick = () => {
    setIsFlipped(!isFlipped);
    if (isFlipped) {
      MixpanelRecord("TB Chart Init", {
        message: "Initiated to land on Team Billability Pie Chart",
      });
    } else if (!isFlipped) {
      MixpanelRecord("TB Table Init", {
        message: "Initiated to land on Team Billability Table",
      });
    }
  };

  useEffect(() => {
    fetch("http://52.146.42.10:9992/api/v1/harvis/getBillabilityStatus", {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          setValue(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // console.log("billable",value.billable)
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ["Non Billable", "Billable"],
      datasets: [
        {
          data: [value.nonBillable, value.billable],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--orange-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--orange-400"),
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          position: "right",
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [value]);

  const {
    query: { username, policy },
  } = router;

  const props = {
    username,
    policy,
  };

  const name = props.username;

  useEffect(() => {
    fetch("http://52.146.42.10:9992/api/v1/harvis/getBillabilityStatus", {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          setValue(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  // const [ btnval, setbtnVal ] = useState(1);

  const [empVal, setEmpval] = useState([{}]);

  const empname = [];
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    projectName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    percentage: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  // const [isFlipped, setIsFlipped] = useState(false);

  //  const handleClick = () => {
  //    setIsFlipped(!isFlipped);
  //  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  let empObj = {
    name: "",
    project: "",
  };

  useEffect(() => {
    fetch("http://52.146.42.10:9992/api/v1/harvis/getEmployeesDetails", {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.name == "TokenExpiredError") {
          router.push(
            {
              pathname: "/Login",
            },
            "/Login"
          );
        } else {
          setProducts(data);
          // console.log(data, "data");
          products.map((item) => {
            empObj = {
              name: item.name,
              projectName: item.projectName,
              percentage: item.percentage,
            };

            empname.push(empObj);
            // console.log(empname,"empname")
            setEmpval(empname);
          });
        }
      })

      .catch((err) => {});
  }, []);

  const gotoProduct = () => {
    setbtnVal(0);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Products</span>
      <Button icon="pi pi-refresh" rounded raised />
    </div>
  );
  const footer = `In total there are ${
    products ? products.length : 0
  } products.`;

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={{ height: 15 }}
          />
        </span>
      </div>
    );
  };

  const head = renderHeader();

  <Button
    onClick={gotoProduct}
    className="button1"
    style={{ marginLeft: 60, marginTop: -10 }}
    icon="pi pi-table"
  />;

  return (
    <div className={`card ${isFlipped ? "flipped" : ""}`}>
      <div className="card-front">
        <Card className="teambill" style={{ width: 546 }}>
          <div className="grid">
            <div className="col-6">
              <h2
                style={{
                  marginBottom: -40,
                  marginTop: -4,
                  marginLeft: -70,
                  fontFamily: "Didact Gothic",
                  fontSize: 24,
                  color: "black",
                  fontWeight: "900",
                }}
              >
                Team Billability
              </h2>
            </div>
            <div className="col">
              <h3
                style={{
                  marginBottom: -40,
                  marginTop: -4,
                  fontFamily: "Didact Gothic",
                  fontSize: 22,
                  color: "black",
                  fontWeight: "900",
                }}
              >
                B2A: {value.billable}/{value.total}
              </h3>
              <h5
                style={{
                  position: "absolute",
                  marginTop: 40,
                  color: "#808080",
                  fontFamily: "Didact Gothic",
                  fontSize: 15,
                }}
              >
                Last update on 30 August 2024
              </h5>
            </div>

            <div className="col-3">
              <Button
                onClick={handleClick}
                className="button1"
                style={{ marginLeft: 60, marginTop: -10 }}
                icon="pi pi-table"
              />
            </div>
          </div>

          <Chart
            className="sm:w-10rem md:w-26rem lg:w-80rem xl:w-80rem"
            type="doughnut"
            data={chartData}
            options={chartOptions}
            style={{
              //  height: "auto",
              marginLeft: 45,
              marginTop: 20,
              fontFamily: "Didact Gothic",
              fontSize: 24,
              color: "black",
              //  width:"auto",
              fontWeight: "900",
            }}
          />
        </Card>
      </div>
      <div className="card-back">
        <Card>
          <div className="grid">
            <div className="col-6">
              <h2
                style={{
                  marginTop: -10,
                  marginLeft: -60,
                  fontFamily: "Didact Gothic",
                  fontSize: 24,
                  color: "black",
                  fontWeight: "900",
                }}
              >
                Utilization
              </h2>
            </div>

            <div className="col">
              <Button
                className="button1"
                style={{ marginLeft: "83%", marginTop: -10 }}
                icon="pi pi-chart-pie"
                onClick={handleClick}
              />
            </div>
          </div>

          <DataTable
            className="fil"
            header={head}
            value={products}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            filterDisplay="row"
            globalFilterFields={["name", "projectName", "percentage"]}
            emptyMessage="No customers found."
          >
            <Column
              field="name"
              header="Employee Name"
              filter
              filterPlaceholder="Search "
              style={{ minWidth: "10rem" }}
            ></Column>

            <Column
              field="projectName"
              header="Project"
              filter
              filterPlaceholder="Search"
              style={{ minWidth: "10rem" }}
            ></Column>

            <Column
              field="percentage"
              header="Utilization(%)"
              filter
              filterPlaceholder="Search"
              style={{ minWidth: "10rem" }}
            ></Column>
          </DataTable>
        </Card>
      </div>
    </div>
  );
}
