import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import { ProductService } from "../../Services/ProductService";
import { Card } from "primereact/card";
import TeamBillability from "../Revenue/TeamBillability";
import MixpanelRecord from "./../MixpanelEvent";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
export default function Product() {
  const empname = [];
  const router = useRouter();
  const [products, setProducts] = useState([]);
  const [btnval, setbtnVal] = useState(0);
  const [empVal, setEmpval] = useState([{}]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    projectName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    percentage: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  // const [isFlipped, setIsFlipped] = useState(false);

  //  const handleClick = () => {
  //    setIsFlipped(!isFlipped);
  //  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  let empObj = {
    name: "",
    project: "",
  };

  // useEffect(() => {
  //   fetch("http://52.146.42.10:9992/api/v1/harvis/getEmployeesDetails")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setProducts(data);
  //       // console.log(data, "data");
  //       products.map((item) => {
  //         empObj = { name: item.name, projectName: item.projectName, percentage: item.percentage };

  //         empname.push(empObj);
  //         // console.log(empname,"empname")
  //         setEmpval(empname);
  //       });
  //     })

  //     .catch((err) => {});
  // }, [empname]);

  // console.log(empVal, "name");

  const {
    query: { username, policy },
  } = router;

  const props = {
    username,
    policy,
  };

  const name = props.username;
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={{ height: 15 }}
          />
        </span>
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Products</span>
      <Button icon="pi pi-refresh" rounded raised />
    </div>
  );
  const footer = `In total there are ${
    products ? products.length : 0
  } products.`;

  const headers = renderHeader();

  const gotoTeamBillability = () => {
    MixpanelRecord("Team Bill Chart Init", {
      message: "Initiated to land on Team Billability Chart",
    });
    setbtnVal(1);
  };
  return (
    <>
      {/* <div className={`card ${isFlipped ? 'flipped' : ''}`} >
      <div className="card-front"> */}
      <Card style={{ width: 549 }}>
        <div className="grid">
          <div className="col-6">
            <h2 style={{ marginTop: -10, marginLeft: -60 }}>Utilization</h2>
          </div>

          <div className="col">
            <Button
              className="button1"
              style={{ marginLeft: "83%", marginTop: -10 }}
              icon="pi pi-chart-pie"
              onClick={gotoTeamBillability}
            />
          </div>
        </div>

        <DataTable
          className="fil"
          header={headers}
          value={products}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={["name", "projectName", "percentage"]}
          emptyMessage="No customers found."
        >
          <Column
            field="name"
            header="Employee Name"
            filter
            filterPlaceholder="Search "
            style={{ minWidth: "10rem" }}
          ></Column>

          <Column
            field="projectName"
            header="Project"
            filter
            filterPlaceholder="Search"
            style={{ minWidth: "10rem" }}
          ></Column>

          <Column
            field="percentage"
            header="Utilization(%)"
            filter
            filterPlaceholder="Search"
            style={{ minWidth: "10rem" }}
          ></Column>
        </DataTable>
      </Card>
    </>
  );
}
